@import "src/styles/prepend"; .loader {
  color: $color-primary;
  animation: spin-clockwise 1s linear infinite;

  // .loader--animate-anti-clockwise
  &--animate-anti-clockwise {
    animation: spin-anti-clockwise 1s linear infinite;
  }

  // .loader_media
  &--media {
    animation: none;
    // .loader_media .inner-svg
    & :global(.inner-svg) {
      animation: spin-clockwise 3s linear infinite;
      transform-origin: center center;
    }

    // .loader_media .outer-svg
    & :global(.outer-svg) {
      animation: spin-anti-clockwise 3s linear infinite;
      transform-origin: center center;
    }
  }

  // .loader--white
  &--white {
    color: $color-white;
  }
}

@keyframes spin-anti-clockwise {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes spin-clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
