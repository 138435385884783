/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Libraries
2.0 Font imports
3.0 Styles
4.0 Utilities
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 Libraries
--------------------------------------------------------------*/

@import 'lib/bootstrap/bootstrap-grid';
@import 'lib/normalize';

/*--------------------------------------------------------------
2.0 Font imports
--------------------------------------------------------------*/


/*--------------------------------------------------------------
3.0 Styles
--------------------------------------------------------------*/
@import 'prepend';

:root {
  --app-height: 100%;
  // Restaurant color
  --restaurant-color: #ff5454;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  min-width: $layout-min-width;
  background: $color-background;
  color: $color-text;
  font-family: $font-family-primary;
  font-size: $font-size-small;
  line-height: $font-line-height-small;
  font-weight: $font-weight-regular;
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: clip;
}

// Add full height to website
html,
body,
body > div:first-child,
div#__next,
div#__next > div {
  min-height: var(--app-height);
}

// Change Autocomplete styles in Chrome
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  -webkit-text-fill-color: $color-blue-500;
  transition: background-color 5000s ease-in-out 0s, box-shadow .3s ease-in-out;

  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:hover
  &:focus,
  &:hover {
    box-shadow: inset 0 0 0 1px $color-blue-500;
    -webkit-box-shadow: inset 0 0 0 1px $color-blue-500 !important;
  }
}

// Remove default browser border
input,
textarea {
  border: none;
}

// Remove default fieldset styles
fieldset {
  padding: 0;
  border: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a.focus-visible {
  border-radius: $layout-unit * 2;
  outline-offset: 2px;
  outline: 2px solid $color-primary-500;
  z-index: 1;
}

// Hide firefox dotted outline
button::-moz-focus-inner,
button:focus-visible,
[type="button"]:focus-visible,
[type="reset"]:focus-visible,
[type="submit"]:focus-visible {
  outline: 2px solid transparent;
}

h1, .h1 {
  @extend %h1;
}

h2, .h2 {
  @extend %h2;
}

h3, .h3 {
  @extend %h3;
}

h4, .h4 {
  @extend %h4;
}

h5, .h5 {
  @extend %h5;
}

h6, .h6 {
  @extend %h6;
}

/*--------------------------------------------------------------
4.0 Utilities
--------------------------------------------------------------*/

.full-page-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: var(--app-height);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: $color-white;
}

.no-scroll {
  overflow: clip;
}

.underline {
  text-decoration: underline;
}
