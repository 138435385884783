@import "src/styles/prepend"; .layout {
  display: flex;
  flex-direction: column;

  // .layout__header
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: $layout-unit * 5;
    padding-top: $layout-unit * 5;
    position: relative;
    z-index: 4;

    @include media-breakpoint-up(sm) {
      padding-bottom: $layout-unit * 10;
      padding-top: $layout-unit * 10;
    }
  }

  // .layout__logo
  &__logo {
    display: flex;
    position: relative;
    z-index: 1;
  }

  // .layout__main
  &__main {
    flex-grow: 1;
  }
}
