@import "src/styles/prepend"; @keyframes hide {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes slideIn {
  from {
    transform: translateY(calc(-100% - 40px))
  }
  to {
    transform: translateY(0)
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x))
  }
  to {
    transform: translateX(calc(100% + 40px))
  }
}

.toast {
  border-radius: $layout-unit * 2;
  padding: $layout-unit * 2 $layout-unit * 3;
  background-color: $color-white;
  box-shadow: rgba(14, 18, 22, 0.35) 0px 10px 38px -10px, rgba(14, 18, 22, 0.2) 0px 10px 20px -15px;

  // .toast__element data-state="open"
  &[data-state="open"] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  // .toast__element data-state="closed"
  &[data-state="closed"] {
    animation: hide 100ms ease-in;
  }

  // .toast__element data-state="end"
  &[data-swipe="end"] {
    animation: swipeOut 100ms ease-out;
  }

  // .toast__content
  &__content {
    display: flex;
    align-items: center;
  }

  // .toast__description
  &__description {
    margin-top: $layout-unit;
  }

  // .toast__icon
  &__icon {
    margin-right: $layout-unit * 2;
    min-width: max-content;
  }

  // .toast__viewport
  &__viewport {
    position: fixed;
    top: 70px;
    left: 50%;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    width: max-content;
    max-width:90vw;
    transform: translateX(-50%);
    // Above everything and at the same level as the tooltip
    z-index: 3;
  }
}
